<script>
import { mapActions, mapGetters } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';
import COMMISSIONS_STATUSES from '@/constants/commissions_statuses';

export default {
    name: 'Commissions',

    components: { UserModal },

    data: () => ({
        loading: false,
        invest_url: process.env.VUE_APP_INVEST_URL,
        COMMISSIONS_STATUSES: COMMISSIONS_STATUSES,
        filter: {
            code: '',
            percentage: '',
            user_mail: '',
            status: '',
        },

        discount_statuses: [
            {
                name: 'All',
                id: '',
            },
            {
                name: 'Not Used',
                id: '1',
            },
            {
                name: 'Used',
                id: '0',
            },
        ],

        paymentFee: '',
        reservationFee: '',
        percentageDiscount: '',
        generateCode: '',

        rules: {
            required: value => !!value || 'Required.',
            number: value => {
                const pattern = /^((100)|(\d{1,2}(\.\d{0,2})?))$/;
                return pattern.test(value) || 'Invalid entered values';
            },
        },
    }),

    async created() {
        this.loading = true;

        await this.fetchGetCommissions();
        await this.fetchGetAllDiscounts();

        this.loading = false;
    },

    methods: {
        ...mapActions('commissions', [
            'fetchGetAllDiscounts',
            'fetchGetCommissions',
            'postChangePaymentFee',
            'postCreateDiscount',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),

        async submitPaymentFee() {
            let formData = new FormData();
            formData.append('fee', this.form1.paymentFeeRef);
            formData.append('pay_handler', 'stripe');

            await this.postChangePaymentFee(formData).then(data => {
                if (data) {
                    this.$alertify.success('Success commission change');
                    this.fetchGetCommissions();
                    this.$refs.form1.reset();
                } else {
                    this.$alertify.error('Error commission change');
                }
            });
        },

        async submitReservationFee() {
            let formData = new FormData();

            formData.append('fee', this.form2.reservationFeeRef);
            formData.append('pay_handler', 'stripeInvoice');

            await this.postChangePaymentFee(formData).then(data => {
                if (data) {
                    this.$alertify.success('Success commission change');
                    this.fetchGetCommissions();
                    this.$refs.form2.reset();
                } else {
                    this.$alertify.error('Error commission change');
                }
            });
        },

        async submitGenerateCode() {
            await this.postCreateDiscount({
                percentage: this.percentageDiscount,
            }).then(({ code }) => {
                if (code) {
                    this.$alertify.success('Code successfully generated');
                    this.generateCode = code;
                    this.fetchGetAllDiscounts();
                    this.$refs.form4.reset();
                } else {
                    this.$alertify.error('Error generating discount');
                }
            });
        },

        copyText(value) {
            navigator.clipboard.writeText(value);
            this.$alertify.success(`Copied successfully`);
        },

        getColorByStatus(status) {
            let color;
            switch (parseInt(status)) {
                case COMMISSIONS_STATUSES.NOT_USED:
                    color = 'orange';
                    break;
                case COMMISSIONS_STATUSES.USED:
                    color = 'green';
                    break;
                default:
                    color = null;
                    break;
            }
            return color;
        },
    },

    computed: {
        ...mapGetters('commissions', {
            commissions: 'getCommissionsBuyShares',
            all_discount: 'getAllDiscount',
        }),

        form1() {
            return {
                paymentFeeRef: this.paymentFee,
            };
        },

        form2() {
            return {
                reservationFeeRef: this.reservationFee,
            };
        },

        headers() {
            return [
                {
                    text: 'Discount code',
                    value: 'code',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.code.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: '% discount',
                    value: 'percentage',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.percentage.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'User',
                    value: 'user_mail',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.user_mail.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Discount status',
                    value: 'status',
                    filter: value => {
                        return (value + '').includes(this.filter.status);
                    },
                    align: 'center',
                },
            ];
        },
    },
};
</script>

<style scoped>
.button_link {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}
</style>

<template>
    <div>
        <div class="button_link">
            <v-btn
                color="green"
                target="_blank"
                :href="`${invest_url}admin-discount`"
            >
                {{ invest_url }}admin-discount
            </v-btn>
        </div>
        <v-row justify="center">
            <v-col cols="12" sm="3">
                <v-card>
                    <v-form ref="form1">
                        <v-card-text>
                            <p>
                                Card payment fee:
                                <b
                                    >{{
                                        this.commissions.stripe
                                            ? this.commissions.stripe.fee
                                            : 0
                                    }}
                                    %</b
                                >
                            </p>
                            <v-text-field
                                :rules="[rules.number]"
                                ref="paymentFeeRef"
                                v-model="paymentFee"
                                type="number"
                                label="Enter a new fee"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                :disabled="!!!paymentFee"
                                text
                                @click="submitPaymentFee"
                            >
                                Change
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
            <v-col cols="12" sm="3">
                <v-card>
                    <v-form ref="form2">
                        <v-card-text>
                            <p>
                                Reservation fee:
                                <b
                                    >{{
                                        this.commissions.stripe_invoice
                                            ? this.commissions.stripe_invoice
                                                  .fee
                                            : 0
                                    }}
                                    %</b
                                >
                            </p>
                            <v-text-field
                                :rules="[rules.number]"
                                ref="reservationFeeRef"
                                v-model="reservationFee"
                                type="number"
                                label="Enter a new fee"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                :disabled="!!!reservationFee"
                                text
                                @click="submitReservationFee"
                            >
                                Change
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>

            <v-col cols="4">
                <v-card>
                    <v-form ref="form4">
                        <v-card-text>
                            <p>
                                Create discount:
                            </p>
                            <v-text-field
                                ref="discountRef"
                                :rules="[rules.number]"
                                v-model="percentageDiscount"
                                type="number"
                                label="Enter the discount in"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                :disabled="!!!percentageDiscount"
                                text
                                @click="submitGenerateCode"
                            >
                                Generate code
                            </v-btn>
                        </v-card-actions>
                        <template v-if="generateCode">
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-card-text>
                                    Discount code: {{ generateCode }}
                                </v-card-text>
                                <v-btn
                                    color="primary"
                                    @click="copyText(generateCode)"
                                >
                                    Copy
                                </v-btn>
                            </v-card-actions>
                        </template>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>

        <v-divider style="margin-top: 20px"></v-divider>

        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            :headers="headers"
            :items="all_discount"
            :items-per-page="50"
            :footer-props="{
                'items-per-page-options': [10, 20, 40, 60, 80, 100],
            }"
        >
            <template v-slot:[`item.code`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            @click="copyText(item.code)"
                            v-on="on"
                            v-bind="attrs"
                            style="cursor: pointer"
                        >
                            {{ item.code }}
                        </span>
                    </template>
                    <span>Click to copy</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.user_mail`]="{ item }">
                <v-tooltip bottom v-if="item.user_mail">
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.user_mail }}</a
                        >
                    </template>
                    <span>User id {{ item.user_id }}</span>
                </v-tooltip>
                <span v-else>
                    -
                </span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-tooltip
                    bottom
                    v-if="parseInt(item.status) === COMMISSIONS_STATUSES.USED"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Used
                        </v-chip>
                    </template>
                    <span>Used status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        parseInt(item.status) === COMMISSIONS_STATUSES.NOT_USED
                    "
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Not Used
                        </v-chip>
                    </template>
                    <span>Not Used status</span>
                </v-tooltip>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-text-field v-model="filter.code"></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.percentage"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.user_mail"></v-text-field>
                    </td>
                    <td>
                        <v-select
                            :items="discount_statuses"
                            type="text"
                            v-model="filter.status"
                            item-value="id"
                            item-text="name"
                        ></v-select>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <user-modal />
    </div>
</template>
